import React, { Component, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';  // Use Routes and Route for v6
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader';
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {
    render() {
        const menu = routes.map((route, index) => {
            return route.component ? (
                <Route
                    key={index}
                    path={route.path}
                    element={<route.component />} // Use `element` prop for v6
                />
            ) : null;
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            {menu}
                            {/* AdminLayout route */}
                            <Route path="/*" element={<AdminLayout />} />
                        </Routes>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;
